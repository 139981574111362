

  .contact-main-container {
    background: linear-gradient(149deg, #302E2E 0%, rgb(48, 46, 46) 6.73%, #302E2E 50.8%);
    width: 100dvw;
    height: fit-content;
    padding-top: 20px;
    padding-bottom: 100px;
    justify-content: center;
    text-align: center;
  }
  .material-icons-outlined {
    font-family: "Lato";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    /* white-space: nowrap; */
    /* word-wrap: normal; */
    /* direction: ltr; */
    -webkit-font-smoothing: antialiased;
  
  }
  
 

  .contact_wrapper {
    display: flex;
    background: white;
    padding: 40px 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    position: relative;
    width: 700px;
    margin: 0 auto;
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
    box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    box-shadow: rgba(13, 13, 15, 0.621) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    /* opacity: 0;
    transform: translateY(15%); */
    transition: all 3s;
    margin-top: 30px;
  }


  .contact-fade-out {
    opacity: 1;
transform: translate(0);
  }
   

  @media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper {
      width: 80%;
      flex-direction: column;
    }
  }

  @media (max-width: 767px) {
    .contact_wrapper {
      width: 90%;
      flex-direction: column;
      padding-top: 0px;
    }
  }
  .contact_wrapper .contact_info {
    position: relative;
    width: 250px;
    flex: 0 0 230px;
    background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(161, 161, 161, 0.4) 0%, rgba(0,0,0,0.25) 200%);
    background-image: url("../../images/contact1.jpeg");
    background-size:cover;
    /* background-blend-mode: multiply; */
    color: #f6f6f6;
    margin-left: calc(-230px / 2 - 30px);
    padding: 25px;
    /* box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2); */
    box-shadow: 0 25.6px 57.6px 0 rgb(0 0 0 / 22%), 0 4.8px 14.4px 0 rgb(0 0 0 / 18%);
    border-radius: 10px;
   
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper .contact_info {
      width: 100%;
      flex: 0 0 100%;
      margin-left: -50px;
      margin-top: -15px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 767px) {
    .contact_wrapper .contact_info {
      width: 100%;
      flex: 0 0 100%;
      margin-left: -25px;
      margin-top: 0px;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }
  .contact_wrapper .contact_info .title {
    font-size: 24px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .contact_wrapper .contact_info .icons_wrapp {
    margin: 0px;
    padding: 0px;
    list-style: none;
    margin-bottom: 30px;
  }
  .contact_wrapper .contact_info .icons_wrapp li {
    display: flex;
    align-items: baseline;
    margin-bottom: 25px;
  }
  .contact_wrapper .contact_info .icons_wrapp li .icon .material-icons-outlined {
    vertical-align: middle;
    font-size: 24px;
    width: 32px;
  }
  .contact_wrapper .contact_info .icons_wrapp li .text {
    vertical-align: middle;
    line-height: 1.4;
  }
  .contact_wrapper .contact_info .soci_wrap {
    margin: 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    position: absolute;
    bottom: 25px;
  }
  .contact_wrapper .contact_info .soci_wrap li {
    margin: 0px 5px;
  }
  .contact_wrapper .contact_info .soci_wrap li a {
    color: #f4f4f4;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  .contact_wrapper .contact_info .soci_wrap li a:hover {
    color: #fff38f;
  }
  .contact_wrapper .contact_info .soci_wrap li a .material-icons-outlined {
    font-size: 32px;
  }
  .contact_wrapper .contact_msg {
    width: calc(100% - 230px / 2 - 55px);
    flex: 0 0 calc(100% - 230px / 2 - 55px);
    padding: 25px 30px;
    padding-bottom: 0px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    .contact_wrapper .contact_msg {
      width: calc(100% - 25px - 30px);
      flex: 0 0 calc(100% - 25px - 30px);
    }
  }
  @media (max-width: 767px) {
    .contact_wrapper .contact_msg {
      width: calc(100%);
      flex: 0 0 calc(100%);
      padding: 25px 0px;
    }
  }
  .contact_wrapper .contact_msg .title {
    font-size: 24px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 0 15px;
    color: #ffcd39;
  }
  .contact_wrapper .contact_msg .form_fild {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .contact_wrapper .contact_msg .form_fild .input_group {
    position: relative;
    margin-bottom: 25px;
    padding: 0 15px;
  }
  .contact_wrapper .contact_msg .form_fild .input_group.w_50 {
    width: calc(50% - 30px);
    flex: 0 0 calc(50% - 30px);
  }
  @media (max-width: 767px) {
    .contact_wrapper .contact_msg .form_fild .input_group.w_50 {
      width: calc(100% - 30px);
      flex: 0 0 calc(100% - 30px);
    }
  }



  .contact_wrapper .contact_msg .form_fild .input_group.w_100 {
    width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
  .contact_wrapper .contact_msg .form_fild .input_group .input {
    width: 100%;
    min-height: 30px;
    padding: 5px 0px;
    border: none;
    font-size: 18px;
    color: #444;
    outline: none;
    font-weight: 400;
    border-bottom: solid 2px #e9e9e9;
  }
  .contact_wrapper .contact_msg .form_fild .input_group .input.input_textarea {
    min-width: 100%;
    min-height: 75px;
    max-height: 75px;
    max-width: 100%;
  }
  .contact_wrapper .contact_msg .form_fild .input_group .input:hover {
    border-bottom-color: #ffce3968;
  }
  .contact_wrapper .contact_msg .form_fild .input_group .input:focus {
    border-bottom-color: #ffcd39;
  }
  .contact_wrapper .contact_msg .form_fild .input_group .input:valid ~ .placeholder, .contact_wrapper .contact_msg .form_fild .input_group .input:focus ~ .placeholder {
    top: -50px;
    font-size: 12px !important;
    color: #ffcd39;
  }
  .contact_wrapper .contact_msg .form_fild .input_group .input:valid ~ .placeholder.textarea, .contact_wrapper .contact_msg .form_fild .input_group .input:focus ~ .placeholder.textarea {
    top: -12px;
  }
  .contact_wrapper .contact_msg .form_fild .input_group .placeholder {
    position: absolute;
    left: 15px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #bbb;
    font-weight: 400;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  .contact_wrapper .contact_msg .form_fild .input_group .placeholder.textarea {
    align-items: baseline;
  }
   .contact-btn {
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: #ffcd39;
    color: white;
    font-family: "Lato";
    font-weight: bold;
    font-size: 20px;
    min-width: 120px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .contact-btn:hover {
    background-color: #e8b827;
  }
   .contact-btn:active {
    transform: scale(0.95);
  }
@media screen and (max-width: 1050px) {
  .contact_wrapper {
    opacity: 1;
    transform: translate(0);
  }
  
  .contact_wrapper .contact_info {
    background-image: none;
    display: none;
  }

}

@media screen and (max-width: 700px) {
  .contact_wrapper .contact_msg .title {
    font-size: 20px;
    margin: 0px;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 0 15px;
    color: #ffcd39;
  } 

  .contact_wrapper .contact_info {
    background-image: none;
    display: none;
  }

}

@media screen and (max-width: 500px) {
  .contact_wrapper .contact_msg .title {
    font-size: 16px;
  } 

.contact-btn {
    font-size: 18px;

  }

  .contact_wrapper {
    padding-top: 20px;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: none;
  }

  .contact_wrapper input {
    background-color: rgba(255, 255, 255, 0);
  }

  .contact_wrapper textarea {
    background-color: rgba(255, 255, 255, 0);
  }

  .contact_info {
    display: none;
  }
}

