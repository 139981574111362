.about-container {
    /* background: linear-gradient(149deg, #302E2E 0%, rgb(48, 46, 46) 6.73%, #302E2E 50.8%); */
    /* background-color: #383636fe; */
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    margin: 0;
    width: 100dvw;
    max-width: 100%;
    overflow-x: hidden;
    justify-content: center;
    padding-bottom: 100px;
    /* position: relative;
    z-index: -11; */
    padding-top: 80px ;
    background-color: rgb(249, 248, 248);

}

.about-right-left-container {
    margin-top: 60px;
    display: flex;
    align-items: center;
}

.about-left {
    /* padding: 10px; */
    text-align: left;
    color: rgba(53, 52, 52, 0.879);
    font-weight: medium;
    font-size: 21px;

    font-family: "Lora";
    line-height: 180%;
    width: 55%;
    padding-right: 2rem;
    padding-left    : 2rem;
}

.countries-title.about {
    color:rgba(53, 52, 52, 0.879); ;
}
.about-left strong {
    color: #2b2a2afa;
}
.about-right img {
    height: 380px;
    width: 45dvw;
margin-right: 0;
padding: 0;
border-bottom-left-radius: 500px;
border-top-left-radius: 500px;
border: 3px solid rgba(255, 255, 255, 0.088);
object-fit: cover;
}

.about-right.second img {
    border-bottom-left-radius: 0;
border-top-left-radius: 0;
border-bottom-right-radius: 500px;
border-top-right-radius: 500px;
}

/* X-Small devices (portrait phones, less than 576px) */


/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 

 }

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 

 }

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .about-right img {
        height: 400px;
        width: 40dvw;
   
    }

.about-left {
    width: 60dvw;
    padding-right: 2rem;
    padding-left    : 2rem;
}

 }

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { 

}


@media (max-width: 575.98px) { 

    /* .about-right-left-container {
        display: grid;
    } */

    .about-right img {
        height: 150px;
        width:20dvw;
    margin-right: 0;
   
    border-bottom-left-radius:  500px;
    border-top-left-radius:  500px;
    border: 3px solid rgba(255, 255, 255, 0.088);
    object-fit: cover;
    }
    
    .about-right.second img {
        border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 500px;
    border-top-right-radius: 500px;
    }
    
   

    .about-left {
        padding: 10px;
        text-align: left;
        color: rgba(255, 255, 255, 0.879);
        font-weight: medium;
        font-size: 14px;
    
        font-family: "Lora";
        line-height: 140%;
        width: 80dvw;
        padding-right: 1rem;
        padding-left    : 1rem;
    }
    }
