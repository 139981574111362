:root {
    --color: #ECC325;
    --color: #091a4b;
    --color: #a28617;
    --color: #0B2840;
    --color: #68BBE3;
  }
  

  
  .content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
    
  
  }
  
  .hero {
    position: relative;
    background: linear-gradient(149deg, #302E2E 0%, rgba(48, 46, 46, 0.90) 6.73%, #302E2E 50.8%);
    color: white;
    height: fit-content;
    display: flex;
  
    overflow: hidden;
   
    background-color: transparent;
    box-shadow: none;
    border-top: 2px solid rgba(55, 55, 56, 0.656);
    /* -webkit-box-shadow: 0px -13px 58px 0px rgba(52, 52, 53, 0.62);
    -moz-box-shadow: 0px -13px 58px 0px rgba(62, 61, 61, 0.62);
    box-shadow: 0px -13px 58px 0px rgba(68, 67, 67, 0.62); */
    width: 100dvw;
    max-width: 100%;
    margin-bottom: 0;
  }
  
 
  

  
  /* ========================= */
  
  .waves {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 200px;
    background-color: var(--color);
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
    transition: 500ms;
  }
  
  .waves::before,
  .waves::after {
    content: '';
    position: absolute;
    width: 300vw;
    height: 300vw;
    top: -65vw;
    left: 50%;
    transform: translate(-50%, -75%);
  }
  
  .waves::before {
    border-radius: 44%;
    background: linear-gradient(149deg, #302E2E 0%, rgba(48, 46, 46, 0.90) 6.73%, #302E2E 50.8%);
    animation: waves 15s linear infinite;
  }
  
  .waves::after {
    border-radius: 44%;
    background: linear-gradient(149deg, #302E2E 0%, rgba(48, 46, 46, 0.90) 6.73%, #302E2E 50.8%);
    animation: waves 22s linear infinite;
  }
  
  @keyframes waves {
    0% {
      transform: translate(-50%, -75%) rotate(0deg);
    }
    
    100% {
      transform: translate(-50%, -75%) rotate(360deg);
    }
  }
  


  .footer-basic {
    padding: 20px 20px;
    background-color:#1f1e1e;
    color:#ffffff;
    font-family: 'Raleway';
    justify-content: center;
   
  transition: all 1s;
  width: 100dvw;

}


  .footer-basic ul {
    position: relative;
    z-index: 1;
    padding:0;
    list-style:none;
    text-align:center;
    font-size:1.2rem;

    margin-bottom:0;
    display: flex;
   
    justify-content: center;
    color: #ffffff;
  }
  
  .footer-basic li {
    padding: 2% ;
    margin-bottom: 7px;
  }
  
  .footer-basic ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.8;
  }
  
  .footer-basic ul a:hover {
    opacity:1;
  }
  
  .footer-basic .social {
    text-align:center;
    padding-bottom:10px;
  }
  
  .footer-basic .social > a {
    font-size: 2rem;
  
    width:40px;
    height:40px;
    line-height:40px;
    display:inline-block;
    text-align:center;
    margin:0 7px;
    color:inherit;
    opacity:0.75;
  }
  
  .footer-basic .social > a:hover {
    opacity:0.9;
  }
  
  .footer-basic .copyright {

    text-align:center;
    font-size:1rem;
    color:#ffffff;
    margin-bottom:0;
    font-family: 'Lato';
    flex: 1;
    padding-top: 2px;
  }
  .icon{
    color: #ffffff;
  }
  .list-inline-item{
    font-family: 'Lato';
  }

  .footer{
    position: relative;
    z-index: 1;
    display: block;
    justify-content: center;
 
    color: white;
    padding: 10px;
  }



  
  
  















  .content {
    color: #333;
    font-size: 0.8em;
    padding: 1em;
  }