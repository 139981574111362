.navbar-main-container,
.navbarServ-main-container {
    width: 100dvw;
    max-width: 100%;
    overflow-x:hidden ;
    margin: 0 auto;
    background-color: transparent;
    position: absolute;
    justify-self: center;
    z-index: 1;
    position: fixed;
    background-color: #1f1a1976;
    padding-bottom: 10px;
    top: 0;
    background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.242) 11.2%,rgba(63, 61, 61, 0.425)) 78.9%;
}
.navbar-items-container,
.navbarServ-container {
  font-family: "lato", sans-serif;
    list-style-type: none;
    position: relative;
    padding: 0;
    width: 98%;
    min-height: 65px;
    max-height: fit-content;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;

}


.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

.navbar-items {
    float: center;
    justify-content: center;
    display: flex;

}

.navbar-items a {
    text-align: center;
    padding-left: 20px;
    padding-right: 16px;
    text-decoration: none;
    font-size: 18px;
    color: white;
    text-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    position: relative;
    display: inline-block;
}

.navbar-items.nav2 a {
color: black;
}

.navbar-items.nav2.dark a {
  color: rgb(244, 243, 243);
  }

.navbar-button-link::after,
.navbar-button-link:hover::after,
.navbar-button-link.active::after {
    background-color: transparent;
    transform: scaleX(0);
}

.navbar-items a::after {
    content: '';
    position: absolute;
    width: 75%;
    transform: scaleX(0);
    height: 1px;
    bottom: -10px;
    left: 16px;
    background-color: rgba(255, 255, 255, 0.852);
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
}

.navbar-items a:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navbar-items a.active::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* logo */
.navbar-logo {
    margin-left: 01rem;
    top: 12px;
    position: absolute;
    width: auto;
    height: 70%;
    border-radius: 50%;
    left: 0;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;  
    background-color: rgba(127, 124, 124, 0.563);
    border: 3px solid rgba(3, 3, 3, 0.416);
    cursor: pointer;
}

/* btn */

.navbar-switch {
  position: relative;
  display: inline-block;
 
  align-items: center;
  position: absolute;
  right: 8px;
  top: 15px;
}

.navbar-switch > span {
  position: absolute;
  top: 10px;
  pointer-events: none;
  font-family: 'Lato', Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
  font-weight: bold;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #0d0d0c;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.navbar-switch > span.on {
  left: 0;
  padding-left: 2px;
  color: #0d0d0c;
}

.navbar-switch > span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 97px;
  height: 35px;
  background-color: #0d0d0c;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #0d0d0c;
 
  
  -webkit-moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}



input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
}
/* .btn {
  --color: #ECC325;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .5s;
  border: none;
  background-color: transparent;
  font-family: 'Lato';
  font-weight: bold;
  position: absolute;
  right: 2px;
  top: 15px;
  cursor: pointer;
  font-size: 18px;

 
} */

/* 

.btn div {
  letter-spacing: 2px;
  font-weight: bold;
  background: #ECC325;
  border-radius: 5px;
  color: rgb(1, 1, 1);
  padding: 1rem;
} */

/* .btn::before {
  content: '';
  z-index: -1;
  background-color: var(--color);
  border: 2px solid rgba(190, 190, 190, 0.092);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: rotate(7deg);
  transition: .5s;
  opacity: 0.2;
} */

.btn:hover {
  cursor: pointer;

  transform: scale(1.2);
}

/* .btn:hover::before {
  transform: rotate(0deg);
  opacity: 1;
} */

.btn svg {
  transform: translateX(-200%);
  transition: .5s;
  width: 0;
  opacity: 0;
}
/* 
.btn:hover svg {
  width: 25px;
  transform: translateX(0%);
  opacity: 1;
} */

.btn:active {
  filter: brightness(1.4);
}
/* btn */

.navbar-button:hover {
    box-shadow: none;
}

/* .navbar-switch{
  font-family: 'Lora';
    cursor: pointer;
    border: none;
    padding: 10px 20px 10px 20px;
    border-radius: 100px;
   
    box-shadow: 43px 51px 50px 0px rgba(0, 0, 0, 0.38);
    font-size: 18px;
    transition: background 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
  
    top: 10px;
  background-color: #e0d215;
  font-weight: bold;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
} */

/* BREAK POINTS */
@media screen and (max-width: 1400px)  {
  .navbar-main-container,
.navbarServ-main-container {
    width: 100dvw;
}

.navbar-items button {
 
  padding: 10px 15px 10px 15px;

  right: 0px;

}

}

@media screen and (max-width: 1310px) {
  .navbar-items.nav2 a {
    color: rgb(250, 249, 249);
    }
    .navbar-items button {
        display: none;
    }
    .navbar-switch {
      top: 80%;
      right: 50px;
    }
    .navbar-main-container,
    .navbarServ-main-container {
        top: initial;
        bottom: 0;
        background: #1f1a1900;
    }
.navbar-switch.btn{
  display: none;
}
    .navbar-container,
    .navbarServ-container {
        height: fit-content;
        align-items: center;
        display: flex;
        /* position: relative; */
        padding-top: 20px;
        padding-bottom: 30px;
        width: 100%;

    }

    .navbar-logo {
        position: absolute;
        top: -32%;
        left: -20%;
        z-index: 1;

    }

    .navbar-items-container {
        display: grid;
        
        grid-template-columns: repeat(3, 1fr);
        row-gap: 30px;
        margin: 0 auto;
        width: 70%;
        margin-top: 50px;
        text-align: center;
    }

    .navbar-main-container,
    .navbarServ-main-container {
        position: fixed;
        left: 0;
        width: 100%;
        box-shadow: 0 -1px 4pc rgba(0, 0, 0, 0.15);
        transition: .3s;
    }

    /* show menu */
    .show-menu {
        bottom: 0;
    }



    .nav__icon {
        font-size: 1.2rem;
    }

    .nav__close {
        position: absolute;
        right: 1.3rem;
        top: 0.5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: white;
    }

    .nav__close:hover {
        color: rgba(255, 255, 255, 0.853);
    }

    .nav__toggle {
        display: none;
    }

    .nav__toggle.active {
        display: block;
        font-size: 2.1rem;
        cursor: pointer;
        float: right;
        background-color: transparent;
        margin: 30px;
        color: #020202d0;
    }

    .nav__toggle.active:hover {
        color: #0202029f;
    }

    .navbar-main-container,
    .navbarServ-main-container {
        background-color: transparent;
        box-shadow: none;
    }

    .navbar-main-container.active,
    .navbarServ-main-container.active {
        box-shadow: 0 -1px 4pc rgba(0, 0, 0, 0.15);
    }

    .nav__icon,
    .nav__close {
        display: block;
    }

    .navbar-container,
    .navbarServ-container {

        display: none;

    }

    .navbar-container.active,
    .navbarServ-container.active {
        display: grid;
    }

    .navbar-main-container.active,
    .navbarServ-main-container.active {
        background-color: #1F1A19;
    }
}
@media screen and (max-width: 600px) {
    .navbar-logo {
        display: none;
    }
}

/* small screens */
@media screen and (max-width: 560px) {
    .navbar-items-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        margin: 0 auto;
        width: 80%;
        align-items: center;
        row-gap: 30px;
        text-align: center;
        background-color: #0d0d0c08;
    }

}

@media screen and (max-width: 420px) {
    .navbar-items-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0rem;
        margin: 0 auto;
        width: 80%;
        align-items: center;
        row-gap: 30px;
        text-align: center;
    }


    .navbar-logo {
        display: none;

    }
}



  /* The slider */
  /* Default */
.theme {
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
  }
  
  .theme__fill,
  .theme__icon {
    transition: 0.3s;
    cursor: pointer;
  }
  
  .theme__fill {
    background-color: var(--bg);
    display: block;
    mix-blend-mode: difference;

    inset: 0;
    height: 100%;
    transform: translateX(-100%);
  }
  
  .theme__icon,
  .theme__toggle {
    z-index: 1;
  }
  
  .theme__icon,
  .theme__icon-part {
    position: absolute;
  }
  
  .theme__icon {
    display: block;
    top: 0.6em;
    left: 0.7em;
    width: 1.5em;
    height: 1.5em;
  }
  
  .theme__icon-part {
    border-radius: 50%;
    box-shadow: 0.4em -0.4em 0 0.5em hsl(0,0%,100%) inset;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
    width: 1em;
    height: 1em;
    transition: box-shadow var(--transDur) ease-in-out,
          opacity var(--transDur) ease-in-out,
          transform var(--transDur) ease-in-out;
    transform: scale(0.5);
  }
  
  .theme__icon-part ~ .theme__icon-part {
    background-color: hsl(0,0%,100%);
    border-radius: 0.05em;
    top: 50%;
    left: calc(50% - 0.05em);
    transform: rotate(0deg) translateY(0.5em);
    transform-origin: 50% 0;
    width: 0.1em;
    height: 0.2em;
  }
  
  .theme__icon-part:nth-child(3) {
    transform: rotate(45deg) translateY(0.45em);
  }
  
  .theme__icon-part:nth-child(4) {
    transform: rotate(90deg) translateY(0.45em);
  }
  
  .theme__icon-part:nth-child(5) {
    transform: rotate(135deg) translateY(0.45em);
  }
  
  .theme__icon-part:nth-child(6) {
    transform: rotate(180deg) translateY(0.45em);
  }
  
  .theme__icon-part:nth-child(7) {
    transform: rotate(225deg) translateY(0.45em);
  }
  
  .theme__icon-part:nth-child(8) {
    transform: rotate(270deg) translateY(0.5em);
  }
  
  .theme__icon-part:nth-child(9) {
    transform: rotate(315deg) translateY(0.5em);
  }
  
  .theme__label,
  .theme__toggle,
  .theme__toggle-wrap {
    position: relative;
  }
  
  .theme__toggle,
  .theme__toggle:before {
    display: block;
  }
  
  .theme__toggle {
    background-color: hsl(48,90%,85%);
    border-radius: 25% / 50%;
    box-shadow: 0 0 0 0.125em var(--primaryT);
    padding: 0.25em;
    width: 6em;
    height: 3em;
    -webkit-appearance: none;
    appearance: none;
    transition: background-color var(--transDur) ease-in-out,
          box-shadow 0.15s ease-in-out,
          transform var(--transDur) ease-in-out;
  }
  
  .theme__toggle:before {
    background-color: hsl(48,90%,55%);
    border-radius: 50%;
    content: "";
    width: 2.5em;
    height: 2.5em;
    transition: 0.3s;
  }
  
  .theme__toggle:focus {
    box-shadow: 0 0 0 0.125em var(--primary);
    outline: transparent;
  }
  
  /* Checked */
  .theme__toggle:checked {
    background-color: hsl(198,90%,15%);
  }
  
  .theme__toggle:checked:before,
  .theme__toggle:checked ~ .theme__icon {
    transform: translateX(3em);
  }
  
  .theme__toggle:checked:before {
    background-color: hsl(198,90%,55%);
  }
  
  .theme__toggle:checked ~ .theme__fill {
    transform: translateX(0);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(1) {
    box-shadow: 0.2em -0.2em 0 0.2em hsl(0,0%,100%) inset;
    transform: scale(1);
    top: 0.2em;
    left: -0.2em;
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part ~ .theme__icon-part {
    opacity: 0;
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(2) {
    transform: rotate(45deg) translateY(0.8em);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(3) {
    transform: rotate(90deg) translateY(0.8em);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(4) {
    transform: rotate(135deg) translateY(0.8em);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(5) {
    transform: rotate(180deg) translateY(0.8em);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(6) {
    transform: rotate(225deg) translateY(0.8em);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(7) {
    transform: rotate(270deg) translateY(0.8em);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(8) {
    transform: rotate(315deg) translateY(0.8em);
  }
  
  .theme__toggle:checked ~ .theme__icon .theme__icon-part:nth-child(9) {
    transform: rotate(360deg) translateY(0.8em);
  }
  
  .theme__toggle-wrap {
    margin: 0 0.75em;
  }
  
  @supports selector(:focus-visible) {
    .theme__toggle:focus {
      box-shadow: 0 0 0 0.125em var(--primaryT);
    }
  
    .theme__toggle:focus-visible {
      box-shadow: 0 0 0 0.125em var(--primary);
    }
  }