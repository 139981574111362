.Home-container {

    color: rgb(47, 46, 46);
    padding: 10px;
    font-family: "lato", sans-serif;
    display: flex;
    align-items: center;
    width: 90dvw;
    max-width: 100%;
    overflow-x: hidden;
    margin: 0 auto;
    margin-top: 140px;
    margin-bottom: 140px;
    background-color: rgba(249, 248, 248, 0.885);
    border-radius: 5px;
    padding: 40px;
    box-shadow: 0 25.6px 57.6px 0 rgb(0 0 0 / 22%), 0 4.8px 14.4px 0 rgb(0 0 0 / 18%);
}

.homesection-container {
  width: 100dvw;
  max-width: 100%;
  overflow-x: hidden;
}
.Home-left-container {
    width: 60%;
}

.Home-title {
 
    font-weight: bold;
    width: 100%;
    margin-left: 20px;
    letter-spacing: 4px;
    -webkit-animation: inner-left 1s 1s ease both;
    animation: inner-left 1s 1s ease both;
    color: rgb(47, 46, 46);
    font-size: 5rem;
    line-height: 120%;
    font-family: "Lato", sans-serif;
    font-weight: 900;
 
    -webkit-animation: outer-left 1s 1s ease both, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
          animation: outer-left 1s 1s ease both, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
        
  
}

.Home-Description {
    width: 80%;
    padding-top: 20px;
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.744);
    font-size: 20px;
    line-height: 170%;
    color: rgb(47, 46, 46);
      -webkit-animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
          animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
          -webkit-animation: outer-left 1s 1s ease both, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
          animation: outer-left 1s 1s ease both, text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
}

.Home-right-container img {
   
    width: 40dvw;
    height: 60dvh;
    border-radius: 10px;
    /* box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%); */
    margin-left: 10px;
    -webkit-animation: image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 2s backwards;
    animation: image-in 2s cubic-bezier(0.5, 0, 0.1, 1) 2s backwards;
   transition: all 1s ease-in;
	      
}

/* webkit img */


@-webkit-keyframes image-in {
    from {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
              clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  @keyframes image-in {
    from {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
              clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  @-webkit-keyframes inner-left {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: none;
    }
  }
  @keyframes inner-left {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: none;
    }
  }
  @-webkit-keyframes outer-left {
    from {
      transform: translateX(50%);
    }
    to {
      transform: none;
    }
  }
  @keyframes outer-left {
    from {
      transform: translateX(50%);
    }
    to {
      transform: none;
    }
  }


  @keyframes text-clip {
    from {
      -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
              clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    to {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
/* webkit img */

.Home-right-container {
    width: 50%;
}

.Home-square {

    transform: rotate(59.35deg);

    background:  #d0cfcf58; 
   
    position: absolute;
    right: -620px;
    top: 0;
    width: 160dvh;
    height: 30dvw;
    z-index: -1;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: loader 10s infinite ease-in-out ;
    
}
@keyframes loader {
    100% {
      background-position: -100% 0;
    }
  }
/* Button */
.Home-btn-title-container {
    display: flex;
    align-items: center;
    -webkit-animation: inner-left 1s 1s ease both;
    animation: inner-left 1s 1s ease both;
}

.Home-btn-small-title {
    margin-top: 70px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.815);
    color: rgb(47, 46, 46);
}
.Home-btn {
    --color: #ffcd39;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s;
    border: none;
    background-color: transparent;
    font-family: "lato", sans-serif;
    font-weight: bold;
    margin-left: 20px;
     margin-top: 50px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    animation: slide-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;

}

@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-10px);
              transform: translateY(-10px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(10px);
              transform: translateY(10px);
    }
  } 

.Home-btn .Home-btn-title {
    letter-spacing: 2px;
    font-weight: bold;
    background:#ECC325 ;
    border-radius: 5px;
    color: rgb(27, 26, 26);
    padding: 15px 20px 15px 20px;
    display: flex;
    align-items: center;
}

.Home-btn:hover {
    cursor: pointer;
    animation: none;
}

.uil.uil-arrow-right {
    font-size: 30px;
    display: flex;
    margin-left: 20px;
}




@media screen and (max-width: 1550px) {
  .Home-title {
    font-size: 50px;
    width: 95%;
    margin-left: 45px;
  }

  .Home-Description {
    width: 90%;
    margin-left: 50px;
    font-size: 20px;
  }

  .Home-btn {
    font-family: 'Lora';
    font-weight: bold;
    margin-left:50px;
     margin-top: 30px;

  }

  .Home-btn-small-title {
    margin-top: 45px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.815);
    color: rgb(47, 46, 46);
}


}


@media screen and (max-width: 1300px) {

  .Home-container {
    margin-top: 50px;
  }
  .Home-title {
    font-size: 40px;
    width: 100%;
    margin-left: 45px;
  }

  .Home-Description {
    width: 90%;
    margin-left: 50px;
    font-size: 18px;
  }

  .Home-btn {
    font-family: 'Lora';
    font-weight: bold;
    margin-left:50px;
     margin-top: 30px;
     font-size: 18px;

  }

  .Home-btn-small-title {
    margin-top: 45px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.815);
}


}

@media screen and (max-width: 1250px) {

  .Home-container {
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    width: 100dvw;
    display: grid ;
  }

  .Home-right-container {
    display: none;
  }
  .Home-right-container img {
    display: none;
  }
  .Home-title {
    font-size: 70px;
    width: 70dvw;
    margin-left: 0px;
    margin: 0 auto;
    text-align: center;
  }

  .Home-Description {
    width: 90%;
    width: 70dvw;
    margin-left: 0px;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;

  }

  .Home-btn {
    font-family: 'Lora';
    font-weight: bold;
    margin-left:70%;
     margin-top: 30px;
     font-size: 18px;
    

  }

  .Home-btn-small-title {
    margin-top: 45px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.815);
}

.Home-square {
  display: none;
}

}

@media screen and (max-width: 800px) {

  .Home-container {
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    width: 100dvw;
    display: grid ;
  }

  .Home-right-container {
    display: none;
  }
  .Home-right-container img {
    display: none;
  }
  .Home-title {
    font-size: 50px;
    width: 70dvw;
    margin-left: 0px;
    margin: 0 auto;
    text-align: center;
  }

  .Home-Description {
    width: 90%;
    width: 70dvw;
    margin-left: 0px;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;

  }

  .Home-btn {
    font-family: 'Lora';
    font-weight: bold;
    margin-left:40%;
     margin-top: 30px;
     font-size: 16px;


  }

  .Home-btn-small-title {
    margin-top: 45px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.815);
}
.Home-square {
  display: none;
}
}


@media screen and (max-width: 600px) {

  .Home-container {
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    width: 100dvw;
    display: grid ;
  }


  
  .Home-title {
    font-size: 30px;
    width: 90dvw;
    margin-left: 0px;
    margin: 0 auto;
    text-align: center;
  }

  .Home-Description {
   
    width: 90dvw;
    margin-left: 0px;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;

  }

  .Home-btn {
    font-family: 'Lora';
    font-weight: bold;
    margin-left:0;
     margin-top: 30px;
     font-size: 16px;
    

  }
}


@media screen and (max-width: 320px) {

  .Home-container {
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    width: 100dvw;
    display: grid ;
  }


  
  .Home-title {
    font-size: 25px;
    width: 90dvw;
    margin-left: 0px;
    margin: 0 auto;
    text-align: center;
  }

  .Home-Description {
   
    width: 90dvw;
    margin-left: 0px;
    font-size: 14px;
    text-align: center;
    margin: 0 auto;

  }

  .Home-btn {
    font-family: 'Lora';
    font-weight: bold;
    margin-left:0;
     margin-top: 30px;
     font-size: 16px;
 

  }

  .Home-btn-title {
    padding: 8px 12px 8px 12px;
  }

  .Home-btn-small-title {
    font-size: 14px;
  }
  
}