
.countries-container {
    background: linear-gradient(149deg, #302E2E 0%, rgb(48, 46, 46) 6.73%, #302E2E 50.8%);
    background-color: #383636fe;
    padding-top: 30px;
    padding-bottom: 80px;
 
    text-align: center;
    width: 100dvw;
    overflow-x: hidden;  
    background-color: rgba(249, 248, 248, 0.885);
}

/* collecte -tri -recyclage */


.countreis-second-container {
    /* opacity: 0;
    transform: translateY(15%); */
    transition: all 3s;
}

 .countries-fade-out {
    opacity: 1;
    transform: translate(0);
}
.countries-title {
    color: white;
     font-family: "Lato";
    font-size: 40px;
    position: relative;
    display: inline-block;
    text-align: center;
    justify-self: center;
    margin: 0 auto;

}

.countries-title::after {
    content: '';
    position: absolute;
    width: 70%;
    transform: scaleX(1);
    height: 4px;
    border-radius: 2px;
    bottom: -10px;
    left: 15%;
    right: 15%;
    background-color: #ffcd39;
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
}


.countries-title:hover::after {
    transform: scaleX(0);
    transform-origin: bottom left;
}

.countries-title::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.countries-description { 
    color: rgb(188, 188, 188);
    font-size: 20px;
    font-weight: normal;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    margin-top: 50px;
}

.countries-description span {
    color: #ffcd39;
    font-weight: bold;
    
}

.countries-images-container {
    width: fit-content;
    height: fit-content;
    margin: 0 auto ;
    margin-top: 40px;
    border-radius: 800px;
border: 4px solid rgba(207, 205, 205, 0.422);
background: rgba(154, 152, 152, 0.46);
/* box-shadow: -8px 35px 120px 0px rgba(103, 103, 103, 0.29); */
display: flex;
align-items: center;
padding-left: 20px;
padding-right: 20px;
justify-content: center;
padding-top: 30px;
padding-bottom: 30px;
column-gap: 20px;

}
.countries-image-name {
    display: flex;
    align-items: center;
    column-gap: 8px;
 cursor: pointer;

}

.countries-images-container img {
    height: 66px;
    width: 66px;
    border-radius: 100%;
    border: 2px solid rgba(255, 255, 255, 0.705);
    filter: grayscale(100%);
}

.countries-img-name {
    font-size: 20px;
    color:#ffcd39 ;
    text-transform: uppercase;
    font-weight: 700;
    filter: grayscale(80%);
}

.countries-image-name:hover  img ,
.countries-image-name:hover  .countries-img-name,
.countries-image-name.activeGray  img ,
.countries-image-name.activeGray .countries-img-name{
    filter: grayscale(0%);
    
}





.countries-image-name:hover  img,
.countries-image-name.activeGray  img{
    height: 70px;
    width: 70px;
    box-shadow: 0 25.6px 57.6px 0 rgb(0 0 0 / 22%), 0 4.8px 14.4px 0 rgb(0 0 0 / 18%);
}

.countries-image-name:hover  .countries-img-name,.countries-image-name.activeGray .countries-img-name{
    font-size: 24px;
    text-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}



@media screen and (max-width: 1300px) {
    .countries-title { 
        font-size: 34px;
     }
}


@media screen and (max-width: 1300px) {
    .countries-title { 
        font-size: 28px;
     }

     .countries-description {
        font-size: 18px;
     }

     .countries-images-container {
        display: grid;
        grid-template-columns: auto auto auto ;
        row-gap: 20px;
        border-radius: 30px;
     }
}

@media screen and (max-width:1050){
    .countreis-second-container {
        opacity: 1;
        transform: translateY(0);
      
    }
}
@media screen and (max-width: 700px) {
    .countries-container{
        padding-top: 50px;
        padding-bottom: 50px;

    }
    .countries-title { 
        font-size: 26px;
     }

     .countries-description {
        font-size: 18px;
     }

     .countries-images-container {
        display: grid;
        grid-template-columns:  auto auto ;
        row-gap: 20px;
        border-radius: 30px;
        box-shadow: none;
     }
}


@media screen and (max-width: 500px) {
    .countries-container{
        padding-top: 50px;
        padding-bottom: 50px;

    }
    .countries-title { 
        font-size: 26px;
     }

     .countries-description {
        font-size: 16px;
     }

     .countries-images-container {
        display: grid;
        grid-template-columns:  auto auto ;
        row-gap: 20px;
        border-radius: 30px;
        box-shadow: none;
     }

     .countries-images-container img {
        height: 56px;
        width: 56px;
    }
    
    .countries-img-name {
        font-size: 16px;
    }
    
    
    .countries-image-name:hover  img,
.countries-image-name.activeGray  img{
    height: 60px;
    width: 60px;
}

.countries-image-name:hover  .countries-img-name,.countries-image-name.activeGray .countries-img-name{
    font-size: 18px;
}
}


@media screen and (max-width: 400px) {
    .countries-container{
        padding-top: 30px;
        padding-bottom: 30px;

    }
    .countries-title { 
        font-size: 20px;
     }

     .countries-description {
        margin-top: 30px;
        font-size: 14px;
     }

     .countries-images-container {
        display: grid;
        grid-template-columns:  auto  ;
        row-gap: 20px;

        border: none;
        border-radius: 4px;
        padding-left: 30px;
        padding-right: 30px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
     }

     .countries-images-container img {
        height: 46px;
        width: 46px;
    }
    
    .countries-img-name {
        font-size: 16px;
    }
    
    
    .countries-image-name:hover  img,
.countries-image-name.activeGray  img{
    height: 56px;
    width: 56px;
}

.countries-image-name:hover  .countries-img-name,.countries-image-name.activeGray .countries-img-name{
    font-size: 18px;
}
}