.solution-main-container {
  
    /* background: linear-gradient(149deg, #302E2E 0%, rgb(48, 46, 46) 6.73%, #302E2E 50.8%);
    background-color: #383636fe; */
    height: fit-content;
    margin: 0;
 padding-top: 20px;
  width: 100dvw;
  max-width: 100%;
  overflow-x: hidden;
  padding-bottom: 20px;
  background-color: rgb(249, 248, 248);


}

.solution-container {
   
    position: relative;
    width: 60%;
    height: 200px;
    margin: 0 auto;
    margin-top: 80px;
    border-radius: 20px;
    background: linear-gradient(92deg, #D9D9D9 0%, rgba(228, 227, 227, 0.88) 36.98%, #D9D9D9 100%);
 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
 
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    /* opacity: 0;
    transform: translateY(15%); */
    transition: all 3s;

 

}

.solution-fade-out{
    opacity: 1;
transform: translate(0);
}


.solution-text1  { 
    color: #141414db;
    font-size: 20px ;
    font-weight: 700;
   position: relative;
    /* width: 80%; */
   
}

.solution-text1::after {
    content: "";
    top: -80px;
    right: 0;
    width: 2px;
    height: 210px;
    position: absolute;
    background-color:  rgba(117, 116, 116, 0.643);;
}

.solution-text1.last::after {
    background: transparent;
}


@media screen and (max-width: 1350px) { 
    .solution-container {

        width: 80dvw;
    }
}

@media screen and (max-width: 1050px) { 
    .solution-container {

        width: 90dvw;
        opacity: 1;
        transform: translate(0);
    }
    .solution-text1  {
        font-size: 18px;
    }
}
@media screen and (max-width: 850px) { 
    .solution-container {

        width: 95dvw;
        height: 230px;
        opacity: 1;
        transform: translate(0);
    }
    .solution-text1  {
        font-size: 16px;
    }
    .solution-text1::after {
        content: "";
        top: -100px;
        right: -1px;
        width: 2px;
        height: 240px;
        position: absolute;
        background-color:  rgba(117, 116, 116, 0.643);;
    }
}


@media screen and (max-width: 700px) { 
    .solution-container {

        width: 95dvw;
        height: 240px;
        opacity: 1;
        transform: translate(0);
    }
    .solution-text1  {
        font-size: 16px;
    }
    .solution-text1::after {
        content: "";
        top: -100px;
        right: -1px;
        width: 2px;
        height: 250px;
        position: absolute;
        background-color:  rgba(117, 116, 116, 0.643);;
    }
}


@media screen and (max-width: 600px) { 
    .solution-container {
padding-top: 10px;
padding-bottom: 10px;
        width: 95dvw;
        height: 240px;
       display: grid;
       height: fit-content;
      

       background: #e9e4e400;
      ;
      border-radius: 0;
      border: none;
      box-shadow: none;
      opacity: 1;
      transform: translate(0);
    }
    .solution-text1  {
        font-size: 16px;
        border-top: 2px solid rgba(165, 163, 163, 0.264);
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
      color: white;
    
    }
    .solution-text1 span{
        color: #ECC325;
    };
    .solution-text1::after {
       display: none;
    }
}




/* Second solution */


.solution-main-container img {
    max-width: 100%;
    height: 100%;
  }
  
  .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    gap: 20px;
    width: 100dvw;
    max-width: 100%;
    overflow-x: hidden;
  }
  
  .card {
    position: relative;
    background: #333;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
    padding: 2rem;
    color: #aaa;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.2), 0 0 1rem rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin-top: 80px;
    margin-bottom: 80px;
    box-shadow: -5px -5px 10px #414141 ;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;


  }
  .card__image-container {
    margin: -2rem -2rem 1rem -2rem;
  }
  .card__line {
    opacity: 0;
    animation: LineFadeIn 0.8s 0.8s forwards ease-in;
  }
  .card__image {
    opacity: 0;
    animation: ImageFadeIn 0.8s 1.4s forwards;
  }
  .card__title {
    color: white;
    margin-top: 0;
    font-weight: 800;
    letter-spacing: 0.01em;
  }
  .card__content {
    margin-top: -2rem;
    opacity: 0;
    animation: ContentFadeIn 0.8s 1.6s forwards;
  }
  .card__svg {
    position: absolute;
    left: 0;
    top: 115px;
    top: 12%;

  }
  
  @keyframes LineFadeIn {
    0% {
      opacity: 0;
      d: path("M 0 300 Q 0 300 0 300 Q 0 300 0 300 C 0 300 0 300 0 300 Q 0 300 0 300 ");
      stroke: rgba(28, 39, 42, 0.281);
    }
    50% {
      opacity: 1;
      d: path("M 0 300 Q 50 300 100 300 Q 250 300 350 300 C 350 300 500 300 650 300 Q 750 300 800 300");
      stroke: rgba(28, 39, 42, 0.227);
    }
    100% {
      opacity: 1;
      d: path("M -2 100 Q 50 200 100 250 Q 250 400 350 300 C 400 250 550 150 650 300 Q 750 450 802 400");
      stroke: #68BBE3;
      stroke: rgba(28, 39, 42, 0.517);
    }
  }
  @keyframes ContentFadeIn {
    0% {
      transform: translateY(-1rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes ImageFadeIn {
    0% {
      transform: translate(-0.5rem, -0.5rem) scale(1.05);
      opacity: 0;
      filter: blur(2px);
    }
    50% {
      opacity: 1;
      filter: blur(2px);
    }
    100% {
      transform: translateY(0) scale(1);
      opacity: 1;
      filter: blur(0);
    }
  }







/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) { 

    .container {
     
        gap: 15px;
      }

}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .container {
     
        gap: 10px;
        display: grid;
        margin-top: 80px;
        margin-bottom: 80px;
        gap: 20px;
        
      }

      .card {
        margin-top:0 ;
        margin-bottom: 0;
      }
  }
  /* // Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {
    .card {
        width: 350px;
        padding: 1rem;
      }

      .card h1 {
        font-size: 20px;
      }
   }
  


  /* // X-Small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) { 
    .card {
        width: 80dvw;
        padding: 0.9rem;
      }

      .card h1 {
        font-size: 18px;
      }
   }
  





