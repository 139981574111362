
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:700,900');

::root {
    --verysmall-font: 18px; 
    --Title : 70px; 
    --Normal-font: 20px ;
}



body,
html {
    font-family: "Lato", sans-serif;
    height: 100%;
    width: 100vw;
    max-width: 100%;
    margin: 0;  
    padding: 0;
    letter-spacing: 0.1rem;
    /* min-height: 100vh; */
  
    color: #cbc7a9;
   
    position: relative ;
    transition: all 1s ease-in;
}
body {
    
 overflow-x: hidden;
}

/* * {
    box-sizing: border-box;
} */
.App {
    display: grid;
    /* background: linear-gradient(149deg, #302E2E 0%, rgba(48, 46, 46, 0.90) 6.73%, #302E2E 50.8%);
background: radial-gradient(#333, #000); */
background-color: rgb(241, 240, 240)5b5;

height: 100dvh;

}

.App.loaded {
background: transparent;

}
html {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    scroll-snap-points-y: repeat(100vh);
    background: linear-gradient(149deg, #302E2E 0%, rgba(48, 46, 46, 0.90) 6.73%, #302E2E 50.8%);
    background-size: contain;
   background-repeat: no-repeat;
   background-color: rgb(241, 240, 240)5b5;

  

}



/* .app-section {

    scroll-snap-align: start;
    scroll-snap-stop: always;

} */

a {
    text-decoration: none;
}

