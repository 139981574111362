.galerie-container {
    background: linear-gradient(149deg, #302E2E 0%, rgb(48, 46, 46) 6.73%, #302E2E 50.8%);
    background-color: #383636fe;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
    margin: 0;
    width: 100dvw;
    max-width: 100%;
    overflow-x: hidden;
    justify-content: center;
    padding-bottom: 100px;
}


  .galerie-content {
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    justify-content: center;
    column-gap: 20px;
    animation : galerie-animation 12s infinite ease-in-out;

  }

  .galerie-content:hover {
    animation: none;
  }

  .galerie-button {
    --color: #ffcd39;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s;
    border: none;
    background-color: transparent;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    margin: 0 auto;
     margin-top: 50px;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    animation: slide-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
    background-color: #ffcd39;
    padding: 3px;
  }

  @keyframes galerie-animation  {
    0% {
      opacity: 1;
      margin-left: 0%;
    }
   
    50% {
        
      margin-left: 150%;
    }
   

    100% {
      opacity: 1;
      margin-left: 0%;
    }
  }
 

  .galerie-content img {
    height: 400px;
    width: 300px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  .galerie-content img:hover { box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;}

  .galerie-img.hov {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    box-shadow: 6px 42px 75px -9px rgba(0,0,0,0.76);
    -webkit-box-shadow: 6px 42px 75px -9px rgba(0,0,0,0.76);
    -moz-box-shadow: 6px 42px 75px -9px rgba(0,0,0,0.76);
  }
  
  

  @media (max-width: 1075.98px) { 
    .galerie-content img {
      height: 200px;
      width: 200px;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
  }